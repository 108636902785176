import { Box, Grid} from '@mui/material'
import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import CustomDatatable from '../Utils/CustomDatatable';
import AddRate from './AddRate';
import EditRate from './EditRate';
import DeleteRate from './DeleteRate';
import AddRateViaCsv from './AddRateViaCsv';

const HaulingRate = () => {
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);

  const url = '/HaulingRates/getAllRates.json';
  const tableRef = useRef();

  const columnsData = [
    {
        id: "name",
        headerName: "Name",
    },
    {
        id: "description",
        headerName: "Description",
    },
    {
        id: "rate",
        headerName: "Rate",
    },
    {
        id: "old_rate",
        headerName: "Old Rate",
    },
    {
        id: "rate_modified",
        headerName: "rate updated at",
    },
    {
        id: "sp_branch",
        headerName: "Sp branch",
    },
    {
        id: "short_text",
        headerName: "Short text",
    },
    {
        id: "town_city",
        headerName: "Town city",
    },
    {
        id: "province",
        headerName: "Province",
    },
    {
        id: "minimum_assets",
        headerName: "Minimum assets",
    },
    {
        id: "centigen_branch",
        headerName: "Centigen branch",
    },
    {
        id: "active",
        headerName: "Active",
    },
    {
        id: "created_by",
        headerName: "Created by",
    },
    {
        id: "created",
        headerName: "Created",
        render: (params) => {
            const date = new Date(params.created);
            return format(date, 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        id: "modified",
        headerName: "Modified",
        render: (params) => {
            const date = new Date(params.modified);
            return format(date, 'yyyy-MM-dd HH:mm:ss');
        }
    },
    {
        id: "action",
        headerName: "Action",
        width:  200,
        render: (params) => {
            return (
                <>
                    <Grid container spacing={1} className="flex-row">
                        <EditRate haulingRateData={params} setOnEditOrDelete={setOnEditOrDelete}/>
                        <DeleteRate haulingRateData={params} setOnEditOrDelete={setOnEditOrDelete}/>
                    </Grid>
                </>
            )
        }
    }
];

const RefreshTable = () => {
    // Call the triggerDataFetch function in CustomDatatable to refresh data
    if (tableRef.current) {
        tableRef.current.fetchData(false);
    }
}

useEffect(() => {
    RefreshTable()
}, [onEditOrDelete])

  return (
    <>
        <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', gap: '10px'}}>
            <AddRate setOnEditOrDelete={setOnEditOrDelete}/>
            <AddRateViaCsv setOnEditOrDelete={setOnEditOrDelete}/>
        </Box>
        <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
            <CustomDatatable 
                columnsData={columnsData}
                url={url}
                ref={tableRef}
            />
        </Box>
    </>
  )
}

export default HaulingRate