import { Box, Chip, Grid, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid, gridClasses, GridFooterContainer, GridPagination } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
import AddJobOrder from './AddJobOrder';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomPagination from '../Utils/CustomPagination';
import useSortModel from '../../hooks/UseSortModel';


const CustomFooter = ({ totalHaulingRate, selectedRows }) => {
  const formattedTotal = new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(totalHaulingRate);

  return (
    <GridFooterContainer>
      {/* Your custom footer content */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', padding: '8px', backgroundColor: '#f5f5f5' }}>
        {selectedRows > 0 ? (
          <Typography variant="subtitle1">
            {selectedRows} {selectedRows === 1 ? 'row' : 'rows'} selected
          </Typography>
        ) : (
          <Typography variant="subtitle1">
          </Typography>
        )}
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Total Hauling Rate: {formattedTotal}
        </Typography>
      </Box>
      
      {/* Default pagination footer */}
      <CustomPagination /> 
    </GridFooterContainer>
  );
};

const JobOrders = () => {
  const axios = AxiosCustomConfig();
  const [onEditOrDelete, setOnEditOrDelete] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'job_orders';
  const modelTable = 'JobOrders';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [totalHaulingRate, setTotalHaulingRate] = useState(0);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    { field: 'id', headerName: 'Id', width: 50, minWidth: 20, wrap: true,
    },
    { field: 'job_order_id', headerName: 'JO Id',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          return (
              <Link to={`/jobOrders/${requestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { field: 'trip_date', headerName: 'Trip Date',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
            const date = new Date(params);
            return format(date, 'yyyy-MM-dd');
          }, wrap: true 
    },
    { field: 'assigned_hauler', headerName: 'Assigned hauler',flex: 1, minWidth: 150,},
    { field: 'deployment', headerName: 'Deployment', flex: 1, minWidth: 150,
      renderCell: (params) => {
        if(params.value) {
          const deployments = params.value.split(',').map(deployment => deployment.trim());
          return (
              <>
                <Grid container direction="column">
                  {deployments.map((deployment, index) => (
                    <Grid key={index}>
                      <Link to={`/request/${deployment}`}>{deployment}</Link>
                    </Grid>
                  ))}
                </Grid>
              </>
          );
        }

        return null
      },
    },
    { field: 'pullout', headerName: 'Pullout',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const pullouts = params.value.split(',').map(pullout => pullout.trim());
            return (
                <Grid container direction="column">
                  {pullouts.map((pullout, index) => (
                    <Grid key={index}>
                      <Link to={`/pulloutRequest/${pullout}`}>{pullout}</Link>
                    </Grid>
                  ))}
                </Grid>
          );
          }
          return null
      },
    },
    { field: 'service_request', headerName: 'Service Call',flex: 1, minWidth: 150,
        renderCell: (params) => {
          if(params.value) {
            const serviceCalls = params.value.split(',').map(service => service.trim());
            return (
              <Grid container direction="column">
                {serviceCalls.map((service, index) => (
                  <Grid key={index}>
                    <Link to={`/serviceCallRequests/${service}`}>{service}</Link>
                  </Grid>
                ))}
              </Grid>
            );
          }
          return null
      },
    },
    { field: 'hauling_rate_name', headerName: 'Hauling rate name',flex: 1, minWidth: 150,},
    { field: 'hauling_rate', headerName: 'Hauling rate',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const value = Number(params)

        if (isNaN(value)) {
          return '-'; // Handle non-numeric values
        }
        return value.toFixed(2); // Formats to 2 decimal places
      }
    },
    { field: 'status', headerName: 'Status',flex: 1, minWidth: 150,
      renderCell: (params) => {
              
        const status = params.value;
        if (status === 0) {
          return <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} />;
        } else if (status === 1) {
          return <Chip label='Processing' sx={{ backgroundColor: '#D7D6D4'}} />;
        } else if (status === 2) {
          return <Chip label='JO form for completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
        } else if (status === 3) {
          return <Chip label='Delivered' sx={{ backgroundColor: '#3AD658' }} />;
        }  else if (status === 4) {
          return <Chip label='For Billing'  sx={{ backgroundColor: '#2174AE', color: '#fff' }} />;
        } else if (status === 5) {
          return <Chip label='Invoiced' sx={{ backgroundColor: '#661B66', color: '#fff' }} />;
        } else if (status === 6) {
          return <Chip label='Cancelled' sx={{ backgroundColor: '#D2893C' }} />;
        }
      }
    },
    { 
      field: 'cost_per_unit', 
      headerName: 'Cost per unit',
      flex: 1, 
      minWidth: 100,
      valueFormatter: (params) => {
        const value = Number(params)

        if (isNaN(value)) {
          return '-'; // Handle non-numeric values
        }
        return Number(value).toFixed(2);
      }
    },
    { 
      field: 'invoice_number', 
      headerName: 'Invoice no.',
      flex: 1, 
      minWidth: 100,
    },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      flex: 1,
      minWidth: 200,
      wrap: true,
      renderCell: (params) => (
        <>
          {/* <EditAssetSize assetSizeData={params.row} setOnEditOrDelete={setOnEditOrDelete}/>
          <DeleteAssetSize assetSizeData={params.row} setOnEditOrDelete={setOnEditOrDelete}/> */}
        </>
      ),
    },
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/JobOrders/getAllJobOrders.json',
        }
        const response = await axios(options);
        const data = response.data;

        setAssetTypes(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEditOrDelete]);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/JobOrders/getAllJobOrdersId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }


  useEffect(() => {
    const getTotalHaulingRate = async () => {
      try {
        const options = {
          'method': 'POST',
          'url' : '/JobOrders/getTotalHaulingRate.json',
        }
        const response = await axios(options);
        const { totalHaulingRate } = response.data;

        setTotalHaulingRate(totalHaulingRate)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getTotalHaulingRate();
  }, []);

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AddJobOrder setOnEditOrDelete={setOnEditOrDelete}/>
      </Box>
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={assetTypes}
        columns={filteredAndOrderedColumns}
        getRowHeight={() => 'auto'}
        pageSizeOptions={pageSizeOptions}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar 
              selectedRows={rowSelectionModel} 
              columns={columns}
              setOnSaveColumns={setOnSaveColumns}
              functionName={functionName}
              modelTable={modelTable}
              handleClickAllData={handleClickAllData}
              selectAll={selectAll}
            />,
            footer: () => <CustomFooter totalHaulingRate={totalHaulingRate} selectedRows={rowSelectionModel.length}/>
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['asc', 'desc']}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default JobOrders