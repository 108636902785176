import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import {  isValidSpecialCharacter, objectToFormData, validateSingleFileExtension, validateSingleFileSize } from '../GenericFunctions/GenericFunctions';
import styled from '@emotion/styled';
import { CloudUpload } from '@mui/icons-material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { useTheme } from '@emotion/react';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

function EditAuditAsset({auditAssets, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [stores, setStores] = useState([]);
  const [openStore, setOpenStore] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [auditAsset, setAuditAsset] = useState({
    assetSelected:[],
    asset_code: '',
    asset_name: '',
    stores: [], 
    account_unique_code: '',
    asset_selected: '',
    old_asset: ''
  });
  

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    const { account_code, account_name, asset_code, id, asset_name, account_unique_code, remarks, unique_code} = auditAssets;
    setAuditAsset((prevData) => ({ 
      ...prevData, 
      assetSelected: {'label':  `${asset_code} - (${asset_name})`, 'asset_code': asset_code, 'asset_name': asset_name}, 
      old_asset: asset_code, 
      asset_selected: asset_code, 
      stores: {'label': `${account_name} - (${account_code})`, 'account_code': account_code,}, 
      id: id,
      account_unique_code: account_unique_code,
      asset_code: asset_code,
      asset_name: asset_name,
      remarks: remarks,
      unique_code: unique_code,
    }))
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (auditAsset.assetSelected.length === 0) {
        setOpenMessageDialog(true);
        setMessage('Please Select assets')
        return
    }

    if (auditAsset.stores.length === 0 && !auditAsset.account_code) {
        setOpenMessageDialog(true);
        setMessage('Please Select Store name')
        return
    }

    if (!validateSingleFileSize(auditAsset.asset_no_image)) {
      // Collect names of files that exceed the size limit
      setOpenMessageDialog(true);
      setMessage(`Error in asset no. image. The following file(s) exceed the 2 MB limit: ${auditAsset.asset_no_image.name}. Please upload smaller files.`);
      return false;
    }

    if (!validateSingleFileExtension(auditAsset.asset_no_image)) {
      setOpenMessageDialog(true);
      setMessage(`Error in asset no. image. The following file(s) are not image files: ${auditAsset.asset_no_image.name}. Please upload valid image files.`);
      return false;
    }

    if (!validateSingleFileSize(auditAsset.asset_image)) {
      // Collect names of files that exceed the size limit
      setOpenMessageDialog(true);
      setMessage(`Error in asset image. The following file(s) exceed the 2 MB limit: ${auditAsset.asset_image.name}. Please upload smaller files.`);
      return false;
    }

    if (!validateSingleFileExtension(auditAsset.asset_image)) {
      setOpenMessageDialog(true);
      setMessage(`Error in asset image. The following file(s) are not image files: ${auditAsset.asset_image.name}. Please upload valid image files.`);
      return false;
    }

    if (!validateSingleFileSize(auditAsset.branch_image)) {
      // Collect names of files that exceed the size limit
      setOpenMessageDialog(true);
      setMessage(`Error in branch image. The following file(s) exceed the 2 MB limit: ${auditAsset.branch_image.name}. Please upload smaller files.`);
      return false;
    }

    if (!validateSingleFileExtension(auditAsset.branch_image)) {
      setOpenMessageDialog(true);
      setMessage(`Error in branch image. The following file(s) are not image files: ${auditAsset.branch_image.name}. Please upload valid image files.`);
      return false;
    }

    if (!isValidSpecialCharacter(auditAsset.remarks) && auditAsset.remarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Remarks.')
      return
    }
    
    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/AuditAssets/updateAudit.json',
              data: objectToFormData(auditAsset),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setOnEditOrDelete((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const getAssets = async (ignore) => {
    try {
        setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAllDeployedAssets.json',
        }

        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
          setLoading(false)
        }
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getStores = async (ignore) => {
    try {
        setStoreLoading(true)
        const options = {
            method: 'POST',
            url: '/ApiAccounts/getStoresForRequestViaBranch.json',
            data: {unique_code : auditAsset.account_unique_code}
        }

        const response = await axios(options);
        if(!ignore){
          setStores(response.data);
          setStoreLoading(false)
        }

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const resetForm = () => {
    setAuditAsset({
      assetSelected:[],
      asset_code: '',
      asset_name: '',
      stores: [], 
      account_unique_code: '',
      oldAssetSelect:[]
    });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleAssetNoImageChange = (event) => {
    const file = event.target.files[0];
    setAuditAsset((prev) => ({...prev, asset_no_image: file}));
  };
  const handleAssetImageChange = (event) => {
    const file = event.target.files[0];
    setAuditAsset((prev) => ({...prev, asset_image: file}));
  };
  const handleBranchImageChange = (event) => {
    const file = event.target.files[0];
    setAuditAsset((prev) => ({...prev, branch_image: file}));
  };

  const hanldeAssetsChange = (e, selectValue) => {
      if (selectValue) {
        return setAuditAsset((prevData) => ({ ...prevData, assetSelected: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name, asset_selected: selectValue.asset_code, account_unique_code: selectValue.account_unique_code, stores: []}))
      }

      setAuditAsset((prevData) => ({ ...prevData, assetSelected: [], asset_code: '', asset_name:''}))
  }
  const hanldeStoreChange = (e, selectValue) => {
      if (selectValue) {
        return setAuditAsset((prevData) => ({ 
          ...prevData, 
          stores: selectValue,
          
        }))
      }
  }

  useEffect(()=>{
    let ignore = false;

    if (!openAssets) return undefined
    getAssets(ignore);

    return () => {
      ignore = true;
    }
  }, [openAssets]);

  useEffect(()=>{
    let ignore = false;

    if (!openStore) return undefined
    getStores(ignore);

    return () => {
      ignore = true;
    }
  }, [openStore]);

  const [isDisabled, setIsDisabled] = useState(auditAsset.old_asset === auditAsset.asset_selected)
  
  useEffect(() => {
    setIsDisabled(auditAsset.old_asset === auditAsset.asset_selected);
  }, [auditAsset.asset_selected]);


  return (
    <div>
    <Button 
      disabled={auditAssets.status === 1 ? true : false}
      variant="outlined" 
      size="small" 
      sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
      onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Add Audit Asset</DialogTitle>
      <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mt={1}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openAssets}
                  onOpen={() => {setOpenAssets(true);}}
                  onClose={() => {setOpenAssets(false);}}
                  loading={loading}
                  onChange={hanldeAssetsChange}
                  options={assets}
                  value={auditAsset.assetSelected}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select asset*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                disabled={isDisabled}
                open={openStore}
                onOpen={() => {setOpenStore(true);}}
                onClose={() => {setOpenStore(false);}}
                loading={storeLoading}
                onChange={hanldeStoreChange}
                options={stores}
                value={auditAsset.stores}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Store*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {storeLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Asset no Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleAssetNoImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {auditAsset.asset_no_image && (
                  <p>{auditAsset.asset_no_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Asset Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleAssetImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {auditAsset.asset_image && (
                  <p>{auditAsset.asset_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Upload Branch Image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleBranchImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {auditAsset.branch_image && (
                  <p>{auditAsset.branch_image.name}</p>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
            <Typography>Remarks:</Typography>
              <Textarea 
                aria-label="minimum height" 
                minRows={3} 
                placeholder="Input remarks" 
                onChange={(e) => setAuditAsset((prevData) => ({ ...prevData, remarks: e.target.value }))}
                value={auditAsset.remarks}
              />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default EditAuditAsset