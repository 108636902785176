import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, NativeSelect, StyledEngineProvider, TextField, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';


function EditRate({haulingRateData, setOnEditOrDelete}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    name: '',
    description: '',
    rate: '',
    old_rate: '',
    sp_branch: '',
    short_text: '',
    town_city: '',
    province: '',
    minimum_assets: '',
    centigen_branch: '',
    active: 0,
    id: '',
    new_rate: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    const { code, name, description, id, rate, sp_branch, short_text, town_city, province, minimum_assets, centigen_branch, active, old_rate} = haulingRateData;
    setEditData((prevData) => ({ 
      ...prevData, 
      code: code || prevData.code, 
      name: name || prevData.name, 
      description: description || prevData.description, 
      new_rate: rate || prevData.rate, 
      id: id || prevData.id,
      sp_branch: sp_branch || prevData.sp_branch,
      short_text: short_text || prevData.short_text,
      town_city: town_city || prevData.town_city,
      province: province || prevData.province,
      minimum_assets: minimum_assets || prevData.minimum_assets,
      centigen_branch: centigen_branch || prevData.centigen_branch,
      active: active || prevData.active,
      rate: rate || prevData.rate,
      old_rate: old_rate || prevData.old_rate,
    }))
  };

  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const resetForm = () => {
    setEditData({
        name: '',
        description: '',
        rate: '',
        old_rate: '',
        sp_branch: '',
        short_text: '',
        town_city: '',
        province: '',
        minimum_assets: '',
        centigen_branch: '',
        active: 0,
        id: '',
        new_rate: '',
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!editData.name) {
        setOpenMessageDialog(true);
        setMessage('Asset Type is required')
        return
    }

    if (!isValidSpecialCharacter(editData.name)) {
        setOpenMessageDialog(true);
        setMessage('Invalid Special Characters found in Asset Type Name.')
        return
    }

    if (!isValidSpecialCharacter(editData.description) && editData.description !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in Description.')
      return
    }


    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/HaulingRates/updateRate.json',
              data: objectToFormData(editData),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setOpenMessageDialog(true);
          setMessage(response.data.message)
          resetForm();
          setOpen(false)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}.`);
    }
  }

  const handleSelectActiveChange = (event, newValue) => {
    setEditData((prevAccount) => ({ ...prevAccount, active: event.target.value }))
  };
  
  return (
    <div>
      <Button 
        variant="outlined" 
        size="small" 
        sx={{color: theme.palette.secondary.dark, marginRight: '10px'}} 
        onClick={(e) => {handleOpen(e)}}>
      Edit
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        maxWidth={'md'}
        fullWidth={true}
      >
        <DialogTitle>Edit Rate</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                  label="Name*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, name: e.target.value }))}
                  value={editData.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="Rate"
                  label="Rate"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, new_rate: input }));
                          }
                      }
                  }}
                  value={editData.new_rate}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                  disabled
                  name="Rate"
                  label="Old Rate"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, old_rate: input }));
                          }
                      }
                  }}
                  value={editData.old_rate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="description"
                  label="Description"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, description: e.target.value }))}
                  value={editData.description}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="sp_branch"
                  label="SP Branch"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, sp_branch: e.target.value }))}
                  value={editData.sp_branch}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="short_text"
                  label="Short ext"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, short_text: e.target.value }))}
                  value={editData.short_text}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="town_city"
                  label="Town city"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, town_city: e.target.value }))}
                  value={editData.town_city}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="province"
                  label="Province"
                  fullWidth
                  variant="standard"
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, province: e.target.value }))}
                  value={editData.province}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="minimum_assets"
                  label="Minimum assets"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(input) && input >= 0 && input <= 1000000) {
                              setEditData((prevAccount) => ({ ...prevAccount, minimum_assets: input }));
                          }
                      }
                  }}
                  value={editData.minimum_assets}
              />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth sx={{marginTop: 2}}>
                  <InputLabel variant="standard">
                  Active
                  </InputLabel>
                  <NativeSelect
                      defaultValue={editData.active}
                      onChange={handleSelectActiveChange}
                  >
                    <option value={0}>Yes</option>
                    <option value={1}>No</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => 
            {setOpenMessageDialog(false)
            setOnEditOrDelete((prev) => !prev)}
            }
            message={message}
          />
      </StyledEngineProvider>
    </div>
  )
}

export default EditRate