import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';

export const RateSelectionModal = ({ open, onClose, currentRate, oldRate, onConfirm }) => {
  const [selectedRate, setSelectedRate] = useState(currentRate);

  const handleConfirm = () => {
    onConfirm(selectedRate);
    onClose();
  };

  // Reset selected rate when modal opens
  useEffect(() => {
    if (open) {
      setSelectedRate(currentRate);
    }
  }, [open, currentRate]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {oldRate === 0 ? 'Confirm Rate' : 'Select Rate'}
      </DialogTitle>
      <DialogContent>
        {oldRate === 0 ? (
          <Typography>
            Do you want to proceed with the current rate of {currentRate}?
          </Typography>
        ) : (
          <>
            <Typography gutterBottom>
              Please select which rate you would like to use:
            </Typography>
            <RadioGroup
              value={selectedRate}
              onChange={(e) => setSelectedRate(Number(e.target.value))}
            >
              <FormControlLabel
                value={currentRate}
                control={<Radio />}
                label={`Current Rate: ${currentRate}`}
              />
              <FormControlLabel
                value={oldRate}
                control={<Radio />}
                label={`Previous Rate: ${oldRate}`}
              />
            </RadioGroup>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
